<template>
  <!-- Icons -->
  <div class="background">
    <v-main>
      <v-container>
        <v-row class="pt-8" justify="left">
          <v-col cols="12">
            <v-card rounded="50" class="my-10 pa-10">
              <v-card-title
                >Your plan has been downgraded to a Free Plan</v-card-title
              >
              <v-card-text
                >From now on you will only have a limited number of size charts
                and a limited number of product views.</v-card-text
              >
              <v-card-text
                ><strong
                  >Important: you can always upgrade to a Growth Plan.</strong
                >
                All you size charts are saved. The Growth Plan will allow you to
                publish unlimited size charts and have unlimited product views.
                If you have any questions, you can always reach out to us at
                team@smartsize.io</v-card-text
              >
              <v-card-text
                >You can find more information about your Free Plan
                <a
                  href="https://smartsize.zohodesk.eu/portal/en/kb/plans-pricing"
                  target="blank"
                >
                  here <v-icon small>mdi-open-in-new</v-icon></a
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <SmartSizeFooter></SmartSizeFooter>
  </div>
</template>

<script>
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";

export default {
  data() {
    return {};
  },
  components: {
    SmartSizeFooter,
  },
};
</script>

<style>
.background {
  background-color: #f3f5f7;
  min-height: 100vh;
}
</style>